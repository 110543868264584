<template>
  <div>
    <div
      class="
        flex
        justify-between
        mx-1
        pb-2
        block
        text-sm
        font-medium
        leading-5
        text-gray-700
      "
    >
      <div class="w-3/4">Tipo de Regalo</div>
      <div class="w-1/2 text-right">Cantidad</div>
    </div>
    <div
      v-for="(item, i) in productos"
      :key="i"
      class="flex justify-between mx-1"
    >
      <w-select
        class="w-3/4"
        v-if="products"
        label="Tipo de Regalo"
        v-model="productos[i].sku"
        :options="
          products.map((p) => {
            return {
              key: p.sku,
              label: p.name,
              value: p.sku,
            };
          })
        "
      >
      </w-select>
      <w-input class="w-12" v-model="productos[i].cantidad"></w-input>
    </div>
    <div class="border-t border-gray mt-3 flex justify-end">
      <button
        class="
          px-3
          py-1
          mt-2
          font-medium
          text-white
          bg-blue-500
          rounded-md
          shadow
          hover:bg-blue-600
          focus:outline-none
          focus:ring-1
          focus:ring-blue-400
          focus:ring-opacity-75
          text-xs
        "
        @click="agregar"
      >
        Agregar
      </button>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from "feathers-vuex";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      productos: [],
    };
  },
  created() {
    if (this.value) {
      this.productos = this.value;
    }
  },
  watch: {
    productos() {
      this.$emit("input", this.productos);
    },
    value(value) {
      console.log("value", value);
      this.productos = value;
    },
  },
  computed: {
    productsParams() {
      return {
        query: {
          $sort: {
            name: 1,
          },
          $limit: 300,
          $select: ["name", "_id", "type", "sku"],
        },
      };
    },
  },
  methods: {
    agregar() {
      this.productos.push({
        producto: null,
        cantidad: 1,
      });
    },
  },
  mixins: [makeFindMixin({ service: "products" })],
};
</script>

<style>
</style>