<template>
  <div>
    <w-top-bar>Detalles del Cupón</w-top-bar>
    <div v-if="item" class="h-full p-5 bg-gray-200">
      <FeathersVuexFormWrapper :item="item" watch>
        <!-- <template v-slot="{ clone, save, reset, remove }"> -->
        <template v-slot="{ clone, save }">
          <div>
            <div class="flex w-full p-5 text-sm bg-white rounded-md shadow">
              <div class="w-2/5 md:pr-4">
                <div class="text-lg font-medium">Información General</div>
                <div class="text-gray-700">
                  La información se mostrara en la página de promoción del
                  Regalo.
                </div>
              </div>
              <div class="flex-grow">
                <w-input
                  label="Nombre"
                  description="Nombre del destinatario"
                  v-model="clone.beneficiario.nombre"
                />
                <w-input
                  label="Email"
                  description="Correo del destinatario"
                  v-model="clone.beneficiario.email"
                />
                <w-input
                  label="Código"
                  :disabled="true"
                  v-model="clone.codigo"
                  description="Se genera automáticamente, despues de guardar"
                />

                <w-textarea
                  label="Descripción o Notas"
                  v-model="clone.description"
                />

                <div class="mt-5">
                  <w-switch label="Activo" v-model="clone.esGratis"></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    El Regalo desactivado no podra ser utilizado por los
                    clientes.
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5 md:mr-4">
                <div class="text-lg font-medium">Detalles del Regalo</div>
                <div class="text-gray-700">
                  Configura los detalles del Regalo.
                </div>
              </div>
              <div class="flex-grow">
                <div class="mt-5">
                  <div>
                    <w-productos-cantidad v-model="clone.productos" />
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-end py-2">
              <div
                v-if="saved"
                class="flex items-center text-sm font-medium text-green-600"
              >
                <div>Cambios guardados</div>
                <svg
                  class="
                    w-5
                    h-5
                    ml-1
                    mr-2
                    font-normal
                    text-green-600
                    transition
                    duration-150
                    ease-in-out
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div
                v-if="saving"
                class="flex items-center text-sm font-medium text-gray-600"
              >
                <div>Guardando cambios</div>

                <svg
                  class="
                    w-5
                    h-5
                    ml-1
                    mr-2
                    font-normal
                    text-gray-600
                    transition
                    duration-150
                    ease-in-out
                    animate-spin
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </div>
              <button
                @click="doSave(save)"
                class="
                  flex
                  items-center
                  px-4
                  py-2
                  text-sm
                  font-bold
                  text-white
                  transition
                  duration-150
                  ease-in-out
                  bg-green-500
                  rounded
                  hover:bg-green-700
                "
              >
                Guardar
              </button>
            </div>
          </div>
        </template>
      </FeathersVuexFormWrapper>
    </div>
  </div>
</template>

<script>
import { FeathersVuexFormWrapper } from "feathers-vuex";
export default {
  name: "EditGiftCard",
  components: {
    FeathersVuexFormWrapper,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    // Returns a new Todo if the route `id` is 'new', or returns an existing Todo.
    item() {
      const { GiftCard } = this.$FeathersVuex.api;

      return this.id === "new"
        ? new GiftCard()
        : GiftCard.getFromStore(this.id);
    },
  },
  watch: {
    id: {
      handler(val) {
        console.log("id changed", val);

        if (val === "new") {
          return;
        }
        const { GiftCard } = this.$FeathersVuex.api;
        const existingRecord = GiftCard.getFromStore(val);
        console.log("existingRecord", existingRecord);

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          GiftCard.get(val);
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true,
    },
  },
  data() {
    return {
      saved: false,
      saving: false,
    };
  },
  methods: {
    async doSave(save) {
      this.saved = false;
      this.saving = true;

      let saved = await save();
      this.saving = false;
      this.saved = true;
      const self = this;
      setTimeout(() => {
        self.saved = false;
      }, 3000);
      this.handleSaveReponse(saved);
    },
    handleSaveReponse(savedCoupons) {
      // Redirect to the newly-saved item
      console.log("handleSaveReponse", savedCoupons);
      if (this.id === "new") {
        this.$router.push({ params: { id: savedCoupons._id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
